import React, { useEffect, useState } from "react";

// components

import UsersTable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import UserDetail from "../../components/BrokerDetail";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { GetBrokers } from ".././../store/actionCreators/users";
import { Link } from "react-router-dom";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetBrokers(searchValue, page, limit, isVerified));
  }, [page, limit, isVerified]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetBrokers(searchValue, page, limit, isVerified));
    }
  };

  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <UsersTable
            setSearchValue={setSearchValue}
            handleKeyDown={handleKeyDown}
            title="Brokers"
            data={state.users && state.users.allBrokers.items}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
          />
          <Pagination
            page={page}
            onPage={state.users.allBrokers.currentPage}
            setPage={setPage}
            totalPages={state.users.allBrokers.totalPages}
          />
        </div>
      </div>
    </>
  );
}
