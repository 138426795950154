import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { VerifyBroker } from ".././../store/actionCreators/users";
import { GetBroker } from ".././../store/actionCreators/users";
import ImageModal from "./imageModal";
import * as actionTypes from "../../store/action-types";
// components

import UsersAssigned from "./usersAssigned";
import BrokersPayments from "./brokersPayments";

import { Link, useNavigate } from "react-router-dom";
import photo from "../../assets/img/team-4-470x470.png";
import photoId from "../../assets/img/id.jpg";
import Swal from "sweetalert2";

export default function UserDetail(props) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [openUsersAssignedTable, setUsersAssignedTable] = useState(false);
  const [openBrokersPaymentsTable, setBrokersPaymentsTable] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [picture, setPicture] = React.useState(false);

  const role = true;
  // sessionStorage.getItem("user") &&
  // JSON.parse(sessionStorage.getItem("user")).roles[0];

  useEffect(() => {
    dispatch(GetBroker(id));
  }, [id]);

  const selectedBroker = useSelector((state) => state.users.selectedBroker);
  const navigate = useNavigate();

  const VerifyBrokerHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      showCancelButton: true,
      confirmButtonText: "Yes, Verify broker",
      customClass: "slow-animation",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(VerifyBroker(id));
      }
    });
  };

  const goBack = () => {
    dispatch({
      type: actionTypes.FETCH_BROKER_SUCCESS,
      payload: {},
    });
    navigate("/admin/brokers");
  };

  const seeImage = (e, data) => {
    e.preventDefault();
    setPicture(data);
    setShowModal(true);
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
        <ImageModal
          showModal={showModal}
          setShowModal={setShowModal}
          picture={picture}
        />
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            {selectedBroker.isVerified ? (
              <span className="text-xs font-semibold inline-block py-2 px-2 uppercase rounded text-emerald-600 bg-emerald-200 last:mr-0 mr-1">
                Verified
              </span>
            ) : (
              <button
                onClick={() => VerifyBrokerHandler(id)}
                className="bg-emerald-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button">
                <i className="fas fa-check mr-4"></i>
                Verify
              </button>
            )}
            <Link
              to={{
                pathname: `/admin/brokers`,
              }}>
              <button
                onClick={() => goBack()}
                className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button">
                <i className="fas fa-arrow-left mr-4"></i>
                Go Back
              </button>
            </Link>
          </div>
        </div>
        {selectedBroker && (
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
              Broker Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Firstname
                  </label>
                  <input
                    disabled
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={selectedBroker.firstName}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Lastname
                  </label>
                  <input
                    disabled
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={selectedBroker.lastName}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Phone number
                  </label>
                  <input
                    disabled
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={selectedBroker.phoneNumber}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Email address
                  </label>
                  <input
                    disabled
                    type="email"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={selectedBroker.email}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Licence Number
                  </label>
                  <input
                    disabled
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={selectedBroker?.licenseNo}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Province
                  </label>
                  <input
                    disabled
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={selectedBroker?.province}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Preferences
                  </label>
                  <input
                    disabled
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={selectedBroker?.brokerPreferences}
                  />
                </div>
              </div>
            </div>
            <hr className="mt-6 border-b-1 border-slate-300" />

            <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
              Brokerage
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Brokerage
                  </label>
                  <input
                    disabled
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={selectedBroker?.brokerage}
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Brokerage license number
                  </label>
                  <input
                    disabled
                    type="text"
                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={selectedBroker?.brokerageLicenseNo}
                  />
                </div>
              </div>
            </div>
            <hr className="mt-6 border-b-1 border-slate-300" />

            <h6 className="text-slate-400 text-sm mt-8 mb-6 font-bold uppercase">
              Identity
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Photo
                  </label>
                  <img
                    onClick={(e) =>
                      seeImage(
                        e,
                        selectedBroker.picture ? selectedBroker.picture : photo
                      )
                    }
                    className="id-margin"
                    src={
                      selectedBroker.picture ? selectedBroker.picture : photo
                    }
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    ID
                  </label>
                  <img
                    onClick={(e) =>
                      seeImage(
                        e,
                        selectedBroker.idPicture
                          ? selectedBroker.idPicture
                          : photoId
                      )
                    }
                    className="id-margin"
                    src={
                      selectedBroker.idPicture
                        ? selectedBroker.idPicture
                        : photoId
                    }
                  />
                </div>
              </div>
            </div>

            {/* USers assigned TABLE */}
            <>
              <hr className="mt-6 border-b-1 border-slate-300" />
              <h6 className="text-slate-400 text-sm mt-8 mb-6 font-bold uppercase">
                Users assigned
              </h6>

              <div className="rounded-t mb-0 px-4 py-3 border-0 bg-slate-50 ">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 justify-between">
                    {!openUsersAssignedTable && (
                      <button
                        className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={() => setUsersAssignedTable(true)}>
                        Show
                      </button>
                    )}
                    {openUsersAssignedTable && (
                      <button
                        className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={() => setUsersAssignedTable(false)}>
                        Hide
                      </button>
                    )}
                  </div>
                  <div>
                    <button
                      className="bg-orange-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      onClick={() => navigate("/admin/leads")}>
                      Go to Leads
                    </button>
                  </div>
                </div>
              </div>
              {openUsersAssignedTable && (
                <>
                  <UsersAssigned
                    openUsersAssignedTable={openUsersAssignedTable}
                    brokerId={id}
                  />
                </>
              )}
            </>
            <>
              <hr className="mt-6 border-b-1 border-slate-300" />
              <h6 className="text-slate-400 text-sm mt-8 mb-6 font-bold uppercase">
                Payments
              </h6>

              <div className="rounded-t mb-0 px-4 py-3 border-0 bg-slate-50 ">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    {!openBrokersPaymentsTable && (
                      <button
                        className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={() => setBrokersPaymentsTable(true)}>
                        Show
                      </button>
                    )}
                    {openBrokersPaymentsTable && (
                      <button
                        className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={() => setBrokersPaymentsTable(false)}>
                        Hide
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {openBrokersPaymentsTable && (
                <>
                  <BrokersPayments
                    openBrokersPaymentsTable={openBrokersPaymentsTable}
                    brokerId={id}
                  />
                </>
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
}
