import React, { useEffect, useState } from "react";

// components

import Datatable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import UserDetail from "../../components/BrokerDetail";

import { useSelector, useDispatch } from "react-redux";

export default function Tables() {
  console.log(1);
  const thead = ["Name", "Symbol", "Disabled"];

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          {" "}
          <Datatable
            title="Products"
            thead={thead}
            data={state.products && state.products.data}
            detailUrl="/admin/brokers/1"
          />
        </div>
      </div>
    </>
  );
}
