import * as actionTypes from "../action-types";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";

export const GetAdmins = (query, page, limit) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  dispatch({ type: actionTypes.FETCH_ADMINS_REQUEST });

  try {
    const res = await Axios.get(
      `/admin/admins?Page=${page}&PerPage=${limit}&SearchBy=${query}`
    );

    dispatch({
      type: actionTypes.FETCH_ADMINS_SUCCESS,
      payload: res.responseData,
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_ADMINS_FAILURE, error });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  }
};

export const CreateAdmin = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.POST_ADMIN_REQUEST });
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });

  try {
    const res = await Axios.post(`/admin/register`, data);
    dispatch({
      type: actionTypes.POST_ADMIN_SUCCESS,
      payload: res.data,
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });

    Swal.fire("", "Admin added", "success").then(function () {
      window.location.href = "/admin/admins";
    });
  } catch (error) {
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });

    dispatch({ type: actionTypes.POST_ADMIN_FAILURE, error });
    Swal.fire("", error.response.data.message, "info");
  }
};

export const DeleteAdmin = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  dispatch({ type: actionTypes.DELETE_ADMIN_REQUEST });

  try {
    const res = await Axios.delete(`/admin/${id}`);

    dispatch({
      type: actionTypes.DELETE_ADMIN_SUCCESS,
      payload: res.responseData,
    });
    Swal.fire("", "Admin deleted", "success").then(function () {
      window.location.href = "/admin/admins";
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    console.log(error);
    Swal.fire("", error.response.data.message, "info");
    dispatch({ type: actionTypes.DELETE_ADMIN_FAILURE, error });
  }
};

export const ResendPassword = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  dispatch({ type: actionTypes.POST_RESEND_PASSWORD_REQUEST });

  try {
    const res = await Axios.post(`/admin/resenddefaultpassword`, data);

    dispatch({
      type: actionTypes.POST_RESEND_PASSWORD_SUCCESS,
      payload: res.responseData,
    });
    Swal.fire("", res.responseData.message, "success").then(function () {
      window.location.href = "/admin/admins";
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    console.log(error);
    Swal.fire("", error.response.data.message, "info");
    dispatch({ type: actionTypes.POST_RESEND_PASSWORD_FAILURE, error });
  }
};
