import * as actionTypes from "../action-types";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";

export const GetNotifications = (page, limit) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  dispatch({ type: actionTypes.FETCH_NOTIFICATIONS_REQUEST });

  try {
    const res = await Axios.get(
      `/admin/notifications?Page=${page}&Perpage=${limit}`
    );

    dispatch({
      type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
      payload: res.responseData,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });

    dispatch({ type: actionTypes.FETCH_NOTIFICATIONS_FAILURE, error });
  }
};
