import api from "./api";
import TokenService from "./token.service";

const login = (data) => {
  return api.post("/admin/login", data).then((response) => {
    if (response.responseData.tokens) {
      TokenService.setUser(response.responseData.tokens);
      sessionStorage.setItem("role", response.responseData.role);
    }
    return response;
  });
};

const postPassword = (data) => {
  return api.post("/admin/setPassword", data);
};

const forgotPassword = (email) => {
  return api.put(`/account/resendverificationcode/${email}`);
};

const resetPassword = (data) => {
  return api.post("/account/resetPassword", data);
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(sessionStorage.getItem("user"));
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
  postPassword,
  forgotPassword,
  resetPassword,
};

export default AuthService;
