import { combineReducers } from "redux";
import history from "../../history";
import admins from "./adminsReducer";
import users from "./usersReducer";
import notifications from "./notificationsReducer";

import feedback from "./feedbackReducer";

import settings from "./settings";
import account from "./account";
import loading from "./loading";
let reducers = {
  admins,
  users,
  notifications,
  account,
  loading,

  feedback,
  settings,
};
let combinedReducer = combineReducers(reducers);
export default combinedReducer;
