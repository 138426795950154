import React from "react";
import moment from "moment";
import NotificationsDropdown from "../Dropdowns/NotificationsDropdown.js";

export default function Navbar() {
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            {/* <NotificationsDropdown /> */}
          </ul>
          <div
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold m-4"
            href="#pablo"
            onClick={(e) => e.preventDefault()}>
            {moment().format("LLL")}
          </div>

          {/* User */}
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
