let ApiURL = "";

if (process.env.MODE === "development") {
  ApiURL = "https://api.staging.breeze.vogdevelopment.com/api/v1";
}
if (process.env.MODE === "uat") {
  ApiURL = "https://api.uat.breeze.vogdevelopment.com/api/v1";
}
if (process.env.MODE === "production") {
  ApiURL = "https://api.breezemortgagecompany.com/api/v1";
}

export const baseURL = ApiURL;
