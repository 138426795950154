import * as actionTypes from "../action-types";

let initialState = {
  allBrokers: [],
  allLeads: [],
  allCustomers: [],
  loading: false,
  error: null,
  riskLevel: {},
  riskAssessmentHistory: [],
  latestRiskAssessment: {},
  selectedBroker: {},
  brokersCustomers: [],
  brokersPayments: [],
  selectedLeads: [],
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BROKERS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_BROKERS_SUCCESS:
      return {
        ...state,
        allBrokers: action.payload,
      };
    case actionTypes.FETCH_BROKERS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_LEADS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_LEADS_SUCCESS:
      return {
        ...state,
        allLeads: action.payload,
      };
    case actionTypes.FETCH_LEADS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        allCustomers: action.payload,
      };
    case actionTypes.FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.POST_ASSIGN_LEAD_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.POST_ASSIGN_LEAD_SUCCESS:
      return {
        ...state,
        riskAssessmentHistory: action.payload,
      };
    case actionTypes.POST_ASSIGN_LEAD_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_BROKER_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_BROKER_SUCCESS:
      return {
        ...state,
        selectedBroker: action.payload,
      };
    case actionTypes.FETCH_BROKER_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.POST_VERIFY_BROKER_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actionTypes.POST_VERIFY_BROKER_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.POST_VERIFY_BROKER_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_BROKERS_CUSTOMERS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_BROKERS_CUSTOMERS_SUCCESS:
      return {
        ...state,
        brokersCustomers: action.payload,
      };
    case actionTypes.FETCH_BROKERS_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: true,
      };

    case actionTypes.FETCH_BROKERS_PAYMENTS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_BROKERS_PAYMENTS_SUCCESS:
      return {
        ...state,
        brokersPayments: action.payload,
      };
    case actionTypes.FETCH_BROKERS_PAYMENTS_FAILURE:
      return {
        ...state,
        error: true,
      };

    case actionTypes.DISCONNECT_AGENT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.DISCONNECT_AGENT_SUCCESS:
      return {
        ...state,
        // data: action.payload,
      };
    case actionTypes.DISCONNECT_AGENT_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.SET_SELECTED_LEADS:
      return {
        ...state,
        selectedLeads: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
