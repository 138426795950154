import axios from "axios";
import TokenService from "./token.service";
import { baseURL } from "../utils/config";
import Swal from "sweetalert2";

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    if (config.url.split("/")[1] === "reports") {
      config.responseType = "blob";
    }
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/admin/login" && err.response) {
      console.log(originalConfig.url);
      if (originalConfig.url === "/account/refreshtoken") return;
      if (err.response.status === 401 && !originalConfig._retry) {
        // Access Token was expired
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/account/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const { accessToken } = rs.responseData;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          sessionStorage.removeItem("user");
          Swal.fire("Session expired!", "Please login!", "info").then(
            function () {
              window.location.href = "/accounts/login";
            }
          );
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
