import * as actionTypes from "../action-types";

let initialState = {
  data: [],
  allAdmins: [],
  loading: false,
  error: null,
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADMINS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        allAdmins: action.payload,
      };
    case actionTypes.FETCH_ADMINS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.DELETE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actionTypes.DELETE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default userReducer;
