export * as actionCreators from "./actionCreators/users.js";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const FETCH_BROKERS_REQUEST = "FETCH_BROKERS_REQUEST";
export const FETCH_BROKERS_SUCCESS = "FETCH_BROKERS_SUCCESS";
export const FETCH_BROKERS_FAILURE = "FETCH_BROKERS_FAILURE";

export const FETCH_LEADS_REQUEST = "FETCH_LEADS_REQUEST";
export const FETCH_LEADS_SUCCESS = "FETCH_LEADS_SUCCESS";
export const FETCH_LEADS_FAILURE = "FETCH_LEADS_FAILURE";

export const DELETE_ADMIN_ = "DELETE_ADMIN_";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

export const POST_ASSIGN_LEAD_REQUEST = "POST_ASSIGN_LEAD_REQUEST";
export const POST_ASSIGN_LEAD_SUCCESS = "POST_ASSIGN_LEAD_SUCCESS";
export const POST_ASSIGN_LEAD_FAILURE = "POST_ASSIGN_LEAD_FAILURE";

export const POST_ADMIN_REQUEST = "POST_ADMIN_REQUEST";
export const POST_ADMIN_SUCCESS = "POST_ADMIN_SUCCESS";
export const POST_ADMIN_FAILURE = "POST_ADMIN_FAILURE";

export const FETCH_BROKER_REQUEST = "FETCH_BROKER_REQUEST";
export const FETCH_BROKER_SUCCESS = "FETCH_BROKER_SUCCESS";
export const FETCH_BROKER_FAILURE = "FETCH_BROKER_FAILURE";

export const POST_VERIFY_BROKER_REQUEST = "POST_VERIFY_BROKER_REQUEST";
export const POST_VERIFY_BROKER_SUCCESS = "POST_VERIFY_BROKER_SUCCESS";
export const POST_VERIFY_BROKER_FAILURE = "POST_VERIFY_BROKER_FAILURE";

export const FETCH_BROKERS_CUSTOMERS_REQUEST =
  "FETCH_BROKERS_CUSTOMERS_REQUEST";
export const FETCH_BROKERS_CUSTOMERS_SUCCESS =
  "FETCH_BROKERS_CUSTOMERS_SUCCESS";
export const FETCH_BROKERS_CUSTOMERS_FAILURE =
  "FETCH_BROKERS_CUSTOMERS_FAILURE";

export const FETCH_BROKERS_PAYMENTS_REQUEST = "FETCH_BROKERS_PAYMENTS_REQUEST";
export const FETCH_BROKERS_PAYMENTS_SUCCESS = "FETCH_BROKERS_PAYMENTS_SUCCESS";
export const FETCH_BROKERS_PAYMENTS_FAILURE = "FETCH_BROKERS_PAYMENTS_FAILURE";

export const FETCH_ADMINS_REQUEST = "FETCH_ADMINS_REQUEST";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAILURE = "FETCH_ADMINS_FAILURE";

export const DISCONNECT_AGENT_REQUEST = "DISCONNECT_AGENT_REQUEST";
export const DISCONNECT_AGENT_SUCCESS = "DISCONNECT_AGENT_SUCCESS";
export const DISCONNECT_AGENT_FAILURE = "DISCONNECT_AGENT_FAILURE";

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";

export const POST_RESEND_PASSWORD_REQUEST = "POST_RESEND_PASSWORD_REQUEST";
export const POST_RESEND_PASSWORD_SUCCESS = "POST_RESEND_PASSWORD_SUCCESS";
export const POST_RESEND_PASSWORD_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const FETCH_SUBSCRIPTION_CHARGE_REQUEST =
  "FETCH_SUBSCRIPTION_CHARGE_REQUEST";
export const FETCH_SUBSCRIPTION_CHARGE_SUCCESS =
  "FETCH_SUBSCRIPTION_CHARGE_SUCCESS";
export const FETCH_SUBSCRIPTION_CHARGE_FAILURE =
  "FETCH_SUBSCRIPTION_CHARGE_FAILURE";

export const POST_SUBSCRIPTION_CHARGE_REQUEST =
  "POST_SUBSCRIPTION_CHARGE_REQUEST";
export const POST_SUBSCRIPTION_CHARGE_SUCCESS =
  "POST_SUBSCRIPTION_CHARGE_SUCCESS";
export const POST_SUBSCRIPTION_CHARGE_FAILURE =
  "POST_SUBSCRIPTION_CHARGE_FAILURE";

export const FETCH_FEEDBACK_REQUEST = "FETCH_FEEDBACK_REQUEST";
export const FETCH_FEEDBACK_SUCCESS = "FETCH_FEEDBACK_SUCCESS";
export const FETCH_FEEDBACK_FAILURE = "FETCH_FEEDBACK_FAILURE";

export const FETCH_DOCUMENT_REQUEST = "FETCH_DOCUMENT_REQUEST";
export const FETCH_DOCUMENT_SUCCESS = "FETCH_DOCUMENT_SUCCESS";
export const FETCH_DOCUMENT_FAILURE = "FETCH_DOCUMENT_FAILURE";

export const POST_DOCUMENT_REQUEST = "POST_DOCUMENT_REQUEST";
export const POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
export const POST_DOCUMENT_FAILURE = "POST_DOCUMENT_FAILURE";

export const SET_SELECTED_LEADS = "SET_SELECTED_LEADS";

export const VALIDATE = "VALIDATE";

export const SET_USER = "SET_USER";

export const LOGOUT = "LOGOUT";
