import React, { useEffect, useState } from "react";

export default function Index(props) {
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  useEffect(() => {
    if (props.totalPages === props.page) {
      setIsPreviousDisabled(true);
      setIsNextDisabled(true);
    }

    if (props.page === 0) {
      setIsPreviousDisabled(true);
    } else {
      setIsPreviousDisabled(false);
    }

    if (props.onPage === props.totalPages - 1) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  }, [props.page, props.onPage, props.totalPages]);

  return (
    <>
      {props.totalPages > 1 && (
        <div className="btn-wrapper text-center">
          <button
            className={
              "bg-white active:bg-slate-50 text-slate-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150 " +
              (isPreviousDisabled ? "hidden" : "")
            }
            onClick={() => props.setPage(props.page - 1)}>
            <i className="fas fa-arrow-left text-slate-700 mr-4"></i>
            Previous
          </button>
          <span className="m-4">
            Page {props.page + 1} of {props.totalPages}
          </span>
          <button
            className={
              "bg-white active:bg-slate-50 text-slate-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150 " +
              (isNextDisabled ? "hidden" : "")
            }
            onClick={() => props.setPage(props.page + 1)}>
            Next
            <i className="fas fa-arrow-right text-slate-700 mr-4 pl-4"></i>
          </button>
        </div>
      )}
    </>
  );
}
