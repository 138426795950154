import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// layouts

import AdminLayout from "./layouts/Admin.js";

import AuthLayout from "./layouts/Auth.js";

// Auth layouts
import Login from "./views/auth/Login.js";
import NewPassword from "./views/auth/NewPassword";
import ForgotPassword from "./views/auth/ForgotPassword";

//Admin layouts
import Dashboard from "./views/Dashboard";
import Brokers from "./views/Brokers";
import Customers from "./views/Customers";
import Admins from "./views/Admins";
import AddAdmin from "./views/Admins/AddAdmin";

import Products from "./views/Products";
import Leads from "./views/Leads";

import UserDetail from "./components/BrokerDetail";
import Maps from "./components/Forms";
import Settings from "./views/admin/Settings.js";
import TermsOfService from "./views/TermsOfService";
import PrivacyPolicy from "./views/PrivacyPolicy";
import AboutUs from "./views/AboutUs";
import ChangePassword from "./views/ChangePassword";
import Subscription from "./views/Subscription";

import Tables from "./views/admin/Tables.js";
import Feedback from "./views/Feedback";

import AssignLead from "./views/Leads/AssignLead/";

import Landing from "./views/Landing.js";
import Profile from "./views/Profile.js";
import Index from "./views/Index.js";

import { Provider } from "react-redux";
import store from "./store/store";

const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/auth/login" element={<Login />}>
            <Route path="temp" element={<Login />} />
          </Route>

          {/* <Route path="/auth/new-password" element={<NewPassword />} />
          <Route path="/auth/reset-password" element={<NewPassword />} />

          <Route path="/auth/forgot-password" element={<ForgotPassword />} /> */}
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/set-password" element={<NewPassword />} />
          <Route path="/auth/reset-password" element={<NewPassword />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route
            path="*"
            element={<Navigate from="/auth" to="/auth/login" />}
          />
        </Route>

        <Route element={<AdminLayout />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />

          <Route path="/admin/brokers" element={<Brokers />} />
          <Route path="/admin/brokers/:id" element={<UserDetail />} />
          <Route path="/admin/products" element={<Products />} />
          <Route path="/admin/leads" element={<Leads />} />
          <Route path="/admin/customers" element={<Customers />} />
          <Route path="/admin/maps" element={<Maps />} />
          <Route path="/admin/settings" element={<Settings />} />
          <Route path="/admin/tables" element={<Tables />} />
          <Route path="/admin/about-us" element={<AboutUs />} />
          <Route path="/admin/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/admin/terms-of-service" element={<TermsOfService />} />
          <Route path="/admin/change-password" element={<ChangePassword />} />
          <Route path="/admin/subscription" element={<Subscription />} />
          <Route path="/admin/feedback" element={<Feedback />} />

          <Route path="/admin/admins" element={<Admins />} />
          <Route path="/admin/admins/add" element={<AddAdmin />} />

          <Route path="/admin/leads/add" element={<AssignLead />} />
        </Route>

        {/* add routes without layouts */}

        <Route path="/landing" exact component={Landing} />
        <Route path="/profile" exact component={Profile} />
      </Routes>
    </BrowserRouter>{" "}
  </Provider>
);

if (process.env.MODE === "production" || process.env.MODE === "uat") {
  console.log("INSENTRY");
  Sentry.init({
    dsn: "https://e98d965e618d4d50a34f6ee8f84e1c1a@o423451.ingest.sentry.io/4504294275350528",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.8,
    environment: process.env.MODE,
  });
}
