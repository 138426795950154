import React, { useEffect, useState } from "react";
import moment from "moment";

// components

import DataTable from "./datatable";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { GetCustomers } from ".././../store/actionCreators/users";
import { Link } from "react-router-dom";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");

  const thead = [
    "Firstname",
    "Lastname",
    "Email",
    "Phone",
    "Goal",
    "Province",
    "Agent Assigned",
    "Creation Date",
    "Disconnet Agent",
  ];

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetCustomers(searchValue, page, limit));
  }, [page, limit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetCustomers(searchValue, page, limit));
    }
  };
  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          {state.users.allCustomers && (
            <>
              <DataTable
                setSearchValue={setSearchValue}
                handleKeyDown={handleKeyDown}
                title="Customers"
                thead={thead}
                data={state.users && state.users.allCustomers.items}
              />
              <Pagination
                page={page}
                onPage={state.users.allCustomers.currentPage}
                setPage={setPage}
                totalPages={state.users.allCustomers.totalPages}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
