import * as actionTypes from "../action-types";

let initialState = {
  allNotifications: [],
  loading: false,
  error: null,
  singleRiskLevel: {},
};
const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        allNotifications: action.payload,
      };
    case actionTypes.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
};

export default notificationsReducer;
