import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Header from "../components/Headers/Header.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";

// views

import LoadingOverlay from "react-loading-overlay";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";

export default function Admin() {
  const state = useSelector((state) => state);
  return (
    <>
      <Sidebar />
      <div className="relative bg-slate-100 admin-layout">
        <LoadingOverlay
          styles={""}
          active={state.loading.show}
          spinner
          text="Loading, Please wait">
          <Header />
          <div className="px-4 md:px-10 mx-auto w-full -m-24 h-screen">
            <Outlet />

            {/* <FooterAdmin /> */}
          </div>
        </LoadingOverlay>
      </div>
    </>
  );
}
