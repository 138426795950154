import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ChangePassword } from "../../store/actionCreators/settings";
import { checkPassword } from "../../utils/common";
import Swal from "sweetalert2";

export default function Settings() {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.loading.show);

  useEffect(() => {
    if (password === passwordConfirmation) {
      setButtonDisabled(false);
    }
    if (password !== passwordConfirmation) {
      setButtonDisabled(true);
    }
  }, [password, passwordConfirmation]);

  const changePasswordRequest = (e) => {
    e.preventDefault();
    let obj;
    obj = {
      oldPassword,
      password,
      passwordConfirmation,
    };

    dispatch(ChangePassword(obj));
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">
                  Change Password
                </h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={changePasswordRequest}>
                <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Old Password
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <input
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                        }}
                        required
                        type="password"
                        placeholder="Current password"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
                  New Password
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <input
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        required
                        type="password"
                        placeholder="Enter password"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <input
                        onChange={(e) => {
                          setPasswordConfirmation(e.target.value);
                        }}
                        required
                        type="password"
                        placeholder="Confirm password"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <p className="text-sm text-regentGray">Password Rules:</p>
                  <ul className="ml-2 mt-2.5 flex flex-col text-xs font-normal text-regentGray">
                    <li className="flex items-center my-1">
                      <div>
                        {/* <IconCheck height="22" width="22" fill="white" /> */}
                      </div>
                      <p>
                        {" "}
                        {password == passwordConfirmation &&
                          passwordConfirmation &&
                          password && <i className="fas fa-check mr-2"></i>}
                        Both passwords must match
                      </p>
                    </li>
                    <li className="flex items-center my-1">
                      <div>
                        {/* <IconCheck height="22" width="22" fill="white" /> */}
                      </div>
                      <p>
                        {checkPassword(passwordConfirmation) && (
                          <i className="fas fa-check mr-2"></i>
                        )}
                        Minimum 8 letter password, with at least a symbol, upper
                        and lower case letters and a number.
                      </p>
                    </li>
                  </ul>
                </div>
                <button
                  disabled={buttonDisabled}
                  style={
                    buttonDisabled
                      ? { cursor: "not-allowed", backgroundColor: "grey" }
                      : { cursor: "pointer" }
                  }
                  className="bg-indigo-500 mt-4 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit">
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
