import React from "react";
import PropTypes from "prop-types";
// components
import { Link, useNavigate } from "react-router-dom";
import DetailModal from "./detailModal";
import moment from "moment";

export default function CardTable(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [feedback, setFeedback] = React.useState(false);

  const history = useNavigate();
  const resetSearch = () => {
    window.location.reload();
  };

  const seeDetails = (e, data) => {
    e.preventDefault();
    setFeedback(data);
    setShowModal(true);
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }>
        <DetailModal
          showModal={showModal}
          setShowModal={setShowModal}
          feedback={feedback}
        />
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h6 className="text-slate-700 text-2xl font-bold">
                {props.title}
              </h6>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto justify-between">
          {/* <div className="relative mb-3 m-4">
            <input
              style={{ width: "80%" }}
              type="text"
              className=" border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              placeholder="Search"
              onChange={(e) => props.setSearchValue(e.target.value)}
              onKeyDown={props.handleKeyDown}
            />
            <button
              style={{ width: "10%" }}
              onClick={resetSearch}
              className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
              Reset
            </button>
          </div> */}
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {props.thead.map((head, i) => {
                  return (
                    <th
                      key={i}
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                      }>
                      {head}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {props.data && props.data.length > 0 ? (
                props.data.map((feedback, i) => {
                  return (
                    <tr key={i}>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {feedback.title && feedback.title}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {feedback.submittedBy && feedback.submittedBy}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {feedback.userEmail && feedback.userEmail}
                      </td>
                      <td
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 overflow-hidden"
                        style={{ maxWidth: 300 }}>
                        {feedback.body && feedback.body}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {feedback.createdAt
                          ? moment.utc(feedback.createdAt).local().format("LLL")
                          : "Never"}
                      </td>

                      <td
                        onClick={(e) => seeDetails(e, feedback)}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 cursor-pointer font-bold">
                        View
                      </td>

                      {/* <Link
                        to={{
                          pathname: `/admin/feedback/${feedback.id}`,
                          state: { selectedTicket: feedback },
                        }}>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                          <i className="far fa-edit cursor-pointer"></i>
                        </td>
                      </Link> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(props.color === "light"
                          ? "text-slate-600"
                          : "text-white")
                      }>
                      No feedback
                    </span>
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
