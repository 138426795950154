import * as actionTypes from "../action-types";
// import Axios from "axios";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";

export const GetFeedback = (page, limit) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  dispatch({ type: actionTypes.FETCH_FEEDBACK_REQUEST });

  try {
    const res = await Axios.get(`/UserFeedback?Page=${page}&Perpage=${limit}`);

    dispatch({
      type: actionTypes.FETCH_FEEDBACK_SUCCESS,
      payload: res.responseData,
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_FEEDBACK_FAILURE, error });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  }
};
