import * as actionTypes from "../action-types";

let initialState = {
  allFeedback: [],
  loading: false,
  error: null,
  selectedTicket: {},
  riskLevel: {},
  ticketTypes: [],
  ticketComments: {},
  ticketTypesActions: [],
  userProductLimits: [],
};
const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FEEDBACK_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_FEEDBACK_SUCCESS:
      return {
        ...state,
        allFeedback: action.payload,
      };
    case actionTypes.FETCH_FEEDBACK_FAILURE:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
};

export default feedbackReducer;
