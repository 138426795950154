import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { forgotPassword } from "../../store/actionCreators/accounts";

export default function Login() {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.show);

  useEffect(() => {
    if (window.location.pathname === "/auth/login/temp") {
      setIsTempLogin(true);
    }
  }, []);

  const sendForgotPassword = (e) => {
    e.preventDefault();

    sessionStorage.setItem("tempEmail", email);
    dispatch(forgotPassword(email));
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-auto ">
          <div className="w-full lg:w-6/12 px-6">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 mt-32 shadow-lg rounded-lg bg-white  border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-slate-500  text-3xl font-bold">
                    Forgot your Password?
                  </h6>

                  <h6 className="text-slate-500 text-lg mt-2">
                    Please enter the email address associated with the account
                  </h6>
                </div>

                <hr className="mt-6 border-b-1 border-slate-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={sendForgotPassword}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Enter registered email"
                      required
                      value={email}
                      maxLength={150}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      disabled={loading}
                      className="bg-indigo-600 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit">
                      Send
                    </button>
                  </div>
                </form>
                <div className="flex flex-wrap mt-6 relative">
                  <div className="w-1/2">
                    <Link to="/auth/login" className="text-blueGray-200">
                      <small>Go back to Sign in</small>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// function mapStateToProps(state) {
//   return state.accounts;
// }
// export default connect(mapStateToProps, actionCreators)(Login);
