import * as actionTypes from "../action-types";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

// const actionCreators = {
export const validate = () => {
  //TODU
  // return {
  //     type: actionTypes.VALIDATE,
  //     payload: validate()
  // }
  let token = sessionStorage.getItem("access_token");
  if (token) {
    return {
      type: actionTypes.VALIDATE,
      payload: true,
    };
  } else {
    return {
      type: actionTypes.VALIDATE,
      payload: false,
    };
  }
};
export const getLogin = (data, callback) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.login(data);
        dispatch({ type: actionTypes.SET_USER, payload: res.responseData });
        dispatch({ type: actionTypes.VALIDATE, payload: true });
        callback();
        resolve();
      } catch (error) {
        console.log(error);
        Swal.fire("", error.response.data.message, "error");
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const firstTimeLogin = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.firstTimeLogin(data);
        dispatch({ type: actionTypes.VALIDATE, payload: true });
        dispatch({ type: actionTypes.SET_USER, payload: res });
        resolve();
      } catch (error) {
        console.log(error.response);
        Swal.fire("", error.response.data.message, "error");
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const postPassword = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.postPassword(data);
        Swal.fire(
          "Password changed!",
          "Please login to continue!",
          "success"
        ).then(function () {
          window.location.replace("/auth/login");
        });
        resolve();
      } catch (error) {
        console.log(error.response);
        Swal.fire("Error", error.response.data.message, "info");
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const forgotPassword = (email) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.forgotPassword(email);
        Swal.fire(
          "Email sent!",
          "Please check your inbox for the code to reset the password!",
          "success"
        ).then(function () {
          window.location.replace("/auth/reset-password");
        });
        resolve();
      } catch (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "info");
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const resetPassword = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.resetPassword(data);
        Swal.fire(
          "Password changed!",
          "Please login to continue!",
          "success"
        ).then(function () {
          window.location.replace("/auth/login");
        });
        resolve();
      } catch (error) {
        console.log(error.response);
        Swal.fire("Error", error.response.data, "info");
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const changePassword = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.post("/v1/account/changepassword", data);

        Swal.fire("", "Password changed!", "success").then(function () {
          window.location.reload();
        });
        resolve();
      } catch (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "info");
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const Logout = () => {
  sessionStorage.removeItem("access_token");
  return {
    type: actionTypes.VALIDATE,
    payload: false,
  };
};
// };
// export default actionCreators;
