import React from "react";
import PropTypes from "prop-types";
// components
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import TableDropdown from "../../components/Dropdowns/TableDropdown.js";
import photo from "../../assets/img/team-4-470x470.png";
import {
  DeleteAdmin,
  ResendPassword,
} from ".././../store/actionCreators/admins";

export default function CardTable(props) {
  const history = useNavigate();
  const resetSearch = () => {
    window.location.reload();
  };

  const role = sessionStorage.getItem("role") === "superadmin" ? true : false;

  const dispatch = useDispatch();

  const deleteAdmin = (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteAdmin(id));
      }
    });
  };

  const resendPassword = (e, email) => {
    e.preventDefault();

    dispatch(ResendPassword({ email }));
  };

  const addAdmin = () => {
    window.location.replace(`/admin/admins/add`);
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }>
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h6 className="text-slate-700 text-2xl font-bold">
                {props.title}
              </h6>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto justify-between">
          <div className="relative mb-3 m-4">
            <input
              style={{ width: "60%" }}
              type="text"
              className=" border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              placeholder="Search"
              onChange={(e) => props.setSearchValue(e.target.value)}
              onKeyDown={props.handleKeyDown}
            />
            <button
              style={{ width: "10%" }}
              onClick={resetSearch}
              className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
              Reset
            </button>
            <button
              style={{ width: "10%" }}
              onClick={addAdmin}
              className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
              Add Admin
            </button>
          </div>
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Email
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Firstname
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  LastName
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Creation Date
                </th>
                {role && (
                  <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                    Delete
                  </th>
                )}
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Resend Password
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data.map((admin, i) => {
                  return (
                    <tr key={i}>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {admin.email}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {admin.firstName}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {admin.lastName}
                      </td>

                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {moment.utc(admin.creationDate).local().format("LLL")}
                      </td>

                      {role && (
                        <td
                          className="border-t-0 align-middle border-l-0 border-r-0 text-
                       whitespace-nowrap text-left">
                          <i className="far fa-cross cursor-pointer"></i>
                          <button
                            onClick={(e) => deleteAdmin(e, admin.userId)}
                            className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs  px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
                            Delete
                          </button>
                        </td>
                      )}
                      {!admin.isVerified && (
                        <td
                          className="border-t-0 align-middle border-l-0 border-r-0 text-
                       whitespace-nowrap text-center">
                          <i className="far fa-cross cursor-pointer"></i>
                          <button
                            onClick={(e) => resendPassword(e, admin.email)}
                            className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs  px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
                            Send
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
