import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GetBrokersCustomers,
  disconnnetAgent,
} from ".././../store/actionCreators/users";
import PropTypes from "prop-types";
import moment from "moment";
import Pagination from "../../components/Pagination/";
import Swal from "sweetalert2";

export default function CardTable(props) {
  const [openTable, setOpenTable] = useState(false);
  const [btnText, setBtnText] = useState("Show");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("lkjrebflkaewbnk");

    dispatch(GetBrokersCustomers(props.brokerId, page, limit));

    setOpenTable(true);
  }, [props.brokerId, page, limit]);

  const hideTable = () => {
    setOpenTable(false);
  };
  const brokersCustomers = useSelector((state) => state.users.brokersCustomers);

  const thead = [
    "Firstname",
    "Lastname",
    "Email",
    "Phone",
    "Creation Date",
    "Break Connection ",
  ];

  const disconnet = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to disconnet this customer with the agent!",
      showCancelButton: true,
      confirmButtonColor: "#133b8d",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(disconnnetAgent(id));
      }
    });
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }>
        <div className="block w-full overflow-x-auto justify-between">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {thead.map((head, i) => {
                  return (
                    <th
                      key={i}
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                      }>
                      {head}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {brokersCustomers.items && brokersCustomers.items.length > 0 ? (
                brokersCustomers.items.map((cust, i) => {
                  return (
                    <tr key={i}>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                        <span
                          className={
                            "ml-3 font-bold " +
                            +(props.color === "light"
                              ? "text-slate-600"
                              : "text-white")
                          }>
                          {cust.firstName}
                        </span>
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {cust.lastName}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {cust.email}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {cust.phoneNo}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {moment.utc(cust.creationDate).local().format("LLL")}
                      </td>
                      <td
                        onClick={() => disconnet(cust.userId)}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left ">
                        <button className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                          Disconnet
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(props.color === "light"
                          ? "text-slate-600"
                          : "text-white")
                      }>
                      No Users assigned
                    </span>
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        page={page}
        onPage={brokersCustomers.page}
        setPage={setPage}
        totalPages={brokersCustomers.totalPages}
      />
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
