import React from "react";
import moment from "moment";

export default function Modal({ showModal, setShowModal, feedback }) {
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed top-10 mx-auto max-w-3xl feedback-modal">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full  outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t bg-slate-200">
                <h3 className="text-xl font-medium">More info</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}>
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <div className="items-center p-4 border-collapse">
                  <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex">
                    <h3 className="font-medium mr-2">Title: </h3>
                    <span>{feedback?.title}</span>
                  </div>
                  <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                    <h3 className="font-medium mr-2">Submitted By: </h3>
                    <span>{feedback?.submittedBy}</span>
                  </div>
                  <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                    <h3 className="font-medium mr-2">Email:</h3>
                    <span>{feedback?.userEmail}</span>
                  </div>
                  <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                    <h3 className="font-medium mr-2">Phone number:</h3>
                    <span>{feedback?.userPhoneNo}</span>
                  </div>
                  <div className="align-middle p-2 text-left leading-3 flex h-52">
                    <h3 className="font-medium mr-2">Message:</h3>
                    <span style={{ maxHeight: 200, overflowY: "auto" }}>
                      {feedback?.body}
                    </span>
                  </div>
                  <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                    <h3 className="font-medium mr-2">Sent on:</h3>
                    <span>
                      {feedback.createdAt
                        ? moment.utc(feedback.createdAt).local().format("LLL")
                        : "Never"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
