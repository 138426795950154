import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  GetSubscriptionPrice,
  PostSubscriptionPrice,
} from "../../store/actionCreators/settings";
import Swal from "sweetalert2";

export default function Subscription() {
  const [subscriptionCharge, setSubscriptionCharge] = useState(null);
  const [perDealCharge, setPerDealCharge] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [button2Disabled, setButton2Disabled] = useState(true);

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.loading.show);
  const subscriptionPrice = useSelector(
    (state) => state.settings.subscriptionPrice
  );

  useEffect(() => {
    dispatch(GetSubscriptionPrice());
  }, []);

  const changeSubscriptionPriceRequest = (e) => {
    e.preventDefault();
    let obj;
    obj = {
      subscriptionCharge: subscriptionCharge,
    };
    dispatch(PostSubscriptionPrice(obj));
  };

  const changePerDealPriceRequest = (e) => {
    e.preventDefault();
    let obj;
    obj = {
      perDealCharge,
    };
    dispatch(PostSubscriptionPrice(obj));
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">
                  Change Subscription Price
                </h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={changeSubscriptionPriceRequest}>
                <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Current Price
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <input
                        disabled
                        placeholder={`$ ${subscriptionPrice?.subscriptionAmount}`}
                        className="border-0 px-3 py-3 placeholder-slate-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
                  New Price
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <input
                        onChange={(e) => {
                          setSubscriptionCharge(e.target.value);
                        }}
                        required
                        defaultValue={subscriptionCharge}
                        type="number"
                        placeholder="Enter the new price"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <button
                  disabled={subscriptionCharge < 1}
                  style={
                    subscriptionCharge < 1
                      ? { cursor: "not-allowed", backgroundColor: "grey" }
                      : { cursor: "pointer" }
                  }
                  className="bg-indigo-500 mt-4 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit">
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">
                  Change Per Deal Price
                </h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={changePerDealPriceRequest}>
                <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Current Price
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <input
                        disabled
                        placeholder={`$ ${subscriptionPrice?.perDealAmount}`}
                        className="border-0 px-3 py-3 placeholder-slate-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
                  New Price
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <input
                        onChange={(e) => {
                          setPerDealCharge(e.target.value);
                        }}
                        required
                        defaultValue={perDealCharge}
                        type="number"
                        placeholder="Enter the new price"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <button
                  disabled={perDealCharge < 1}
                  style={
                    perDealCharge < 1
                      ? { cursor: "not-allowed", backgroundColor: "grey" }
                      : { cursor: "pointer" }
                  }
                  className="bg-indigo-500 mt-4 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit">
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
