import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { GetBrokers } from "../../../store/actionCreators/users";
import Pagination from "../../../components/Pagination";
import UsersTable from "../../Brokers/datatable";
export default function AddRiskLevel() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [broker, setBroker] = React.useState("");
  const [isVerified, setIsVerified] = useState(false);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { customerId } = useParams();

  useEffect(() => {
    dispatch(GetBrokers(searchValue, page, limit, isVerified));
    setBroker("");
  }, [page, limit, isVerified]);

  const allBrokers = useSelector(
    (state) => state.users.allBrokers,
    shallowEqual
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetBrokers(searchValue, page, limit, isVerified));
    }
  };

  const thead = [
    "Picture",
    "Email",
    "Firstname",
    "Lastname",
    "Creation Date",
    "Verified",
    "Assign Broker",
  ];

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-full px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-xl font-bold">
                  Assign Broker
                </h6>
                <button
                  className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  onClick={() => (window.location.href = "/admin/leads")}>
                  Go back
                </button>
              </div>
            </div>

            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <h6 className="text-slate-400 text-sm mt-3  font-bold uppercase"></h6>

              <div className="flex flex-wrap mb-6">
                <div className="w-full mb-12">
                  <UsersTable
                    setSearchValue={setSearchValue}
                    handleKeyDown={handleKeyDown}
                    thead={thead}
                    data={state.users && state.users.allBrokers.items}
                    isVerified={isVerified}
                    setIsVerified={setIsVerified}
                  />
                  <Pagination
                    page={page}
                    onPage={allBrokers.currentPage}
                    setPage={setPage}
                    totalPages={allBrokers.totalPages}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
