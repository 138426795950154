import React from "react";
import PropTypes from "prop-types";
// components
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import DetailModal from "./detailModal";

export default function CardTable(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [feedback, setFeedback] = React.useState(false);

  const history = useNavigate();
  const resetSearch = () => {
    window.location.reload();
  };

  const seeDetails = (e, data) => {
    e.preventDefault();
    setFeedback(data);
    setShowModal(true);
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }>
        <DetailModal
          showModal={showModal}
          setShowModal={setShowModal}
          feedback={feedback}
        />
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h6 className="text-slate-700 text-2xl font-bold">
                {props.title}
              </h6>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto justify-between">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {props.thead.map((head, i) => {
                  return (
                    <th
                      key={i}
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                      }>
                      {head}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {props.data && props.data.length > 0 ? (
                props.data.map((notification, i) => {
                  return (
                    <tr key={i} className={`${notification.readAt? "":"bg-gray-100"}`}>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {notification.userName && notification.userName}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {notification.userEmail && notification.userEmail}
                      </td>

                      <td
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 overflow-hidden"
                        style={{ maxWidth: 200 }}>
                        {notification.message && notification.message}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {notification.userType && notification.userType}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {notification.createdAt
                          ? moment
                              .utc(notification.createdAt)
                              .local()
                              .format("LLL")
                          : "Never"}
                      </td>

                      <td
                        onClick={(e) => seeDetails(e, notification)}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 cursor-pointer font-bold">
                        View
                      </td>

                      {/* <Link
                        to={{
                          pathname: `/admin/feedback/${feedback.id}`,
                          state: { selectedTicket: feedback },
                        }}>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                          <i className="far fa-edit cursor-pointer"></i>
                        </td>
                      </Link> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(props.color === "light"
                          ? "text-slate-600"
                          : "text-white")
                      }>
                      No feedback
                    </span>
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
