import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// components
import { Link, useNavigate } from "react-router-dom";
import { AssignLead } from "../../store/actionCreators/users";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import TableDropdown from "../../components/Dropdowns/TableDropdown.js";
import photo from "../../assets/img/team-4-470x470.png";

export default function CardTable(props) {
  const [currentUrl, setCurrentUrl] = useState("");
  const history = useNavigate();
  const dispatch = useDispatch();
  const customerIds = useSelector((state) => state.users.selectedLeads);

  const resetSearch = () => {
    window.location.reload();
  };

  const goToUser = (e, brokerId) => {
    e.preventDefault();
    if (currentUrl === "brokers") {
      history(`/admin/brokers/${brokerId}`);
    } else {
      let data = {
        brokerId,
        customerIds,
      };
      dispatch(AssignLead(data));
    }
  };

  useEffect(() => {
    let parts = window.location.pathname.split("/");
    setCurrentUrl(parts[2]);
  }, []);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }>
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h6 className="text-slate-700 text-2xl font-bold">
                {props.title}
              </h6>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto justify-between">
          <div className="relative mb-3 m-4">
            <input
              style={{ width: "80%" }}
              type="text"
              className=" border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              placeholder="Search"
              onChange={(e) => props.setSearchValue(e.target.value)}
              onKeyDown={props.handleKeyDown}
            />
            <button
              style={{ width: "10%" }}
              onClick={resetSearch}
              className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
              Reset
            </button>
          </div>
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Picture
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Email
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Firstname
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Lastname
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Preference
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Province
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Creation Date
                </th>
                <th className="px-6  align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  <button
                    onClick={() => props.setIsVerified(!props.isVerified)}
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-3 py-1 text-xs font-medium text-gray-700 shadow-sm  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                    Sort
                  </button>
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data.map((broker, i) => {
                  return (
                    <tr key={i}>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
                        <img
                          className="table-image w-5 h-5 object-cover"
                          src={broker.picture ? broker.picture : photo}
                        />
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {broker.email}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {broker.firstName}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {broker.lastName}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {broker.preference}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {broker.province}
                      </td>

                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {moment.utc(broker.creationDate).local().format("LLL")}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {broker.isVerified ? (
                          <span
                            style={{
                              color: "#166534",
                              backgroundColor: "#DCFCE7",
                            }}
                            className="inline-flex items-center rounded-full bg-grey-500 px-3 py-1 text-xs font-medium text-yellow-800">
                            Verified
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "#854D0E",
                              backgroundColor: "#FEF9C3",
                            }}
                            className="inline-flex items-center rounded-full bg-grey-500 px-3 py-1 text-xs font-medium text-yellow-800">
                            Unverified
                          </span>
                        )}
                      </td>
                      <td
                        // onClick={() => goToUser(broker.userId)}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap text-left ">
                        <button
                          onClick={(e) => goToUser(e, broker.userId)}
                          className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                          {currentUrl === "brokers" ? "View" : "Select"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
