import React, { useEffect, useState } from "react";
import moment from "moment";

// components

import DataTable from "./datatable";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { GetLeads } from ".././../store/actionCreators/users";
import { Link } from "react-router-dom";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");

  const state = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetLeads(searchValue, page, limit));
  }, [page, limit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetLeads(searchValue, page, limit));
    }
  };

  const actionFunction = (id) => {
    window.location.replace(`/admin/leads/add/${id}`);
  };

  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <DataTable
            setSearchValue={setSearchValue}
            handleKeyDown={handleKeyDown}
            title="Leads"
            data={state && state.allLeads.items}
            actionFunction={actionFunction}
          />
          <Pagination
            page={page}
            onPage={state.allLeads.currentPage}
            setPage={setPage}
            totalPages={state.allLeads.totalPages}
          />
        </div>
      </div>
    </>
  );
}
