import React from "react";
import moment from "moment";
import "../../style.css";

export default function Modal({ showModal, setShowModal, picture }) {
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed top-10 mx-auto max-w-3xl picture-modal">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full  outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between px-5  rounded-lg bg-black">
                <button
                  className="p-1 ml-auto bg-transparent border-0 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}>
                  <span className="bg-transparent text-white opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none mr-4">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <div className="items-center p-4 border-collapse">
                  <img src={picture} className="items-center modal-image" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
