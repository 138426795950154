import * as actionTypes from "../action-types";

let initialState = {
  user: {},
  isLogin: false,
};
function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.VALIDATE:
      return {
        ...state,
        isLogin: action.payload,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
export default reducer;
