import React, { useState } from "react";
import PropTypes from "prop-types";
// components
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import TableDropdown from "../../components/Dropdowns/TableDropdown.js";
import photo from "../../assets/img/team-4-470x470.png";
import * as actionTypes from "../../store/action-types";
import { useSelector, useDispatch } from "react-redux";

export default function CardTable(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedLeads = useSelector((state) => state.users.selectedLeads);

  const resetSearch = () => {
    window.location.reload();
  };

  const goToUser = (id) => {
    navigate(`/admin/leads/add/`);
  };

  const handleCheckboxChange = (event) => {
    let newArray = [...selectedLeads, event.target.id];
    if (selectedLeads.includes(event.target.id)) {
      newArray = newArray.filter((lead) => lead !== event.target.id);
    }
    dispatch({
      type: actionTypes.SET_SELECTED_LEADS,
      payload: newArray,
    });
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }>
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h6 className="text-slate-700 text-2xl font-bold">
                {props.title}
              </h6>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto justify-between">
          <div className="relative mb-3 m-4">
            <input
              style={{ width: "80%" }}
              type="text"
              className=" border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              placeholder="Search"
              onChange={(e) => props.setSearchValue(e.target.value)}
              onKeyDown={props.handleKeyDown}
            />
            <button
              style={{ width: "10%" }}
              onClick={resetSearch}
              className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
              Reset
            </button>
          </div>
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {selectedLeads.length > 0 ? (
                  <th className="pl-4 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                    <button
                      onClick={() => goToUser()}
                      type="button"
                      className="inline-flex items-center rounded border border-sky-600 bg-sky-600 px-3 py-1 text-xs font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                      Assign Leads
                    </button>
                  </th>
                ) : (
                  <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                    Select
                  </th>
                )}

                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Email
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Firstname
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Lastname
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Phone
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Goal
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Province
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">
                  Creation Date
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data && props.data.length > 0 ? (
                props.data.map((lead, i) => {
                  return (
                    <tr key={i}>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        <input
                          checked={selectedLeads.includes(lead.userId)}
                          id={lead.userId}
                          name={lead.userId}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          onChange={handleCheckboxChange}
                        />
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {lead.email}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {lead.firstName}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {lead.lastName}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {lead.phoneNo}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {lead.goal}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {lead.province}
                      </td>

                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {moment.utc(lead.creationDate).local().format("LLL")}
                      </td>
                      {/* <td
                        onClick={() => goToUser(lead.userId)}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left ">
                        <i className="far fa-edit cursor-pointer"></i>
                      </td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(props.color === "light"
                          ? "text-slate-600"
                          : "text-white")
                      }>
                      No leads
                    </span>
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
