/*eslint-disable*/
import React from "react";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";

import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown.js";
import BreezeLogo from "../../assets/img/logo_on_light.svg";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const location = useLocation();
  const state = useSelector((state) => state);
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const logOut = () => {
    AuthService.logout();
  };
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-72 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <div className="md:block mx-auto md:pb-2 text-slate-600 inline-block whitespace-nowrap text-sm uppercase font-bold p-2">
            <img width="250px" src={BreezeLogo} />
          </div>
          {/* User */}

          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-slate-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-slate-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Breeze
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Communication
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className={"items-center"}>
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/dashboard"
                >
                  <div className="flex items-center">
                    <i
                      className={
                        "fas fa-bell mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/dashboard") !== -1
                          ? "opacity-75"
                          : "text-slate-300")
                      }
                    ></i>
                    <div>Notifications </div>
                    <div className="w-3"></div>
                    <div className="h-5 w-5 ml-3 flex items-center justify-center bg-red-600 text-white rounded-full text-xs">
                      {state.notifications?.allNotifications?.items?.filter(i=>!i.readAt).length ||
                        0}
                    </div>
                  </div>
                  {/* <span className="m-4">
                    <button
                      className="bg-orange-500 text-white w-5 h-5 rounded-full outline-none focus:outline-none"
                      type="button">
                      3
                    </button>
                  </span> */}
                </Link>
              </li>

              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/limit-groups") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/limit-groups">
                  <i
                    className={
                      "fas fa-funnel-dollar mr-2 " +
                      (window.location.href.indexOf("/admin/limit-groups") !==
                      -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }></i>
                  Limits Groups
                </Link>
              </li> */}

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/feedback") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/feedback"
                >
                  <i
                    className={
                      "fas fa-envelope-open-text mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/feedback") !== -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>{" "}
                  Feedback
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Users
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/brokers") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/brokers"
                >
                  <i
                    className={
                      "fas fa-user mr-3 text-sm " +
                      (window.location.href.indexOf("/admin/brokers") !== -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>
                  Brokers
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/customers") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/customers"
                >
                  <i
                    className={
                      "fas fa-users mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/customers") !== -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>
                  Customers
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/leads") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/leads"
                >
                  <i
                    className={
                      "fas fa-asterisk mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/leads") !== -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>{" "}
                  Leads
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/admins") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/admins"
                >
                  <i
                    className={
                      "fas fa-clipboard-list mr-3 text-sm " +
                      (window.location.href.indexOf("/admin/admins") !== -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>{" "}
                  Admins
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Settings
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/terms-of-service") !==
                    -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/terms-of-service"
                >
                  <i
                    className={
                      "fas fa-star-of-life mr-2 " +
                      (window.location.href.indexOf(
                        "/admin/terms-of-service"
                      ) !== -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>
                  Terms of Service
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/privacy-policy") !==
                    -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/privacy-policy"
                >
                  <i
                    className={
                      "fas fa-shield-alt mr-2 " +
                      (window.location.href.indexOf("/admin/privacy-policy") !==
                      -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>
                  Privacy Policy
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/about-us") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/about-us"
                >
                  <i
                    className={
                      "far fa-file-alt mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/about-us") !== -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>{" "}
                  About Us
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/change-password") !==
                    -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/change-password"
                >
                  <i
                    className={
                      "fas fa-key mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/change-password"
                      ) !== -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>
                  Change Password
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase p-3 font-bold block " +
                    (window.location.href.indexOf("/admin/subscription") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-slate-700 hover:text-slate-500")
                  }
                  to="/admin/subscription"
                >
                  <i
                    className={
                      "fas fa-funnel-dollar mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/subscription") !==
                      -1
                        ? "opacity-75"
                        : "text-slate-300")
                    }
                  ></i>
                  Subscription
                </Link>
              </li>
            </ul>

            {/* Heading */}
            <hr className="my-4 md:min-w-full" />

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  onClick={logOut}
                  className="text-slate-700 hover:text-slate-500 text-xs uppercase p-3 font-bold block"
                  to="/auth/login"
                >
                  <i className="fas fa-sign-out-alt text-slate-400 mr-2 text-sm"></i>{" "}
                  Log out
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
