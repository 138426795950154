import React, { useEffect, useState } from "react";

// components

import TicketsTable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { GetNotifications } from ".././../store/actionCreators/notifications";
import { Link } from "react-router-dom";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");

  const thead = [
    "Submitted by",
    "Email",
    "Message",
    "User type",
    "Creation Date",
    "Action",
  ];

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetNotifications(page, limit));
  }, [page, limit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetNotifications(page, limit));
    }
  };
  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <TicketsTable
            setSearchValue={setSearchValue}
            handleKeyDown={handleKeyDown}
            title="Notifications"
            thead={thead}
            data={
              state.notifications && state.notifications.allNotifications.items
            }
          />
          <Pagination
            page={page}
            onPage={state.notifications.allNotifications.currentPage}
            setPage={setPage}
            totalPages={state.notifications.allNotifications.totalPages}
          />
        </div>
      </div>
    </>
  );
}
