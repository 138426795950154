import * as actionTypes from "../action-types";
// import Axios from "axios";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";

export const GetBrokers =
  (query, page, limit, isVerified) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_BROKERS_REQUEST });

    try {
      const res = await Axios.get(
        `/admin/brokers?Page=${page}&PerPage=${limit}&SearchBy=${query}&isVerified=${isVerified}`
      );

      dispatch({
        type: actionTypes.FETCH_BROKERS_SUCCESS,
        payload: res.responseData,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_BROKERS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetBroker = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_BROKER_REQUEST });

  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    const res = await Axios.get(`/Admin/broker/${id}`);
    dispatch({
      type: actionTypes.FETCH_BROKER_SUCCESS,
      payload: res.responseData,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });

    dispatch({
      type: actionTypes.FETCH_BROKER_FAILURE,
      error,
    });
  }
};

export const GetBrokersPayments =
  (brokerId, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_BROKERS_PAYMENTS_REQUEST });

    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true });

      const res = await Axios.get(
        `/admin/broker/payments?Page=${page}&PerPage=${limit}&brokerId=${brokerId}`
      );

      dispatch({
        type: actionTypes.FETCH_BROKERS_PAYMENTS_SUCCESS,
        payload: res.responseData,
      });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_BROKERS_PAYMENTS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
      Swal.fire("", error.response.data.message, "info");
    }
  };

export const GetLeads = (query, page, limit) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  dispatch({ type: actionTypes.FETCH_LEADS_REQUEST });

  try {
    const res = await Axios.get(
      `/admin/leads?Page=${page}&PerPage=${limit}&SearchBy=${query}`
    );

    dispatch({
      type: actionTypes.FETCH_LEADS_SUCCESS,
      payload: res.responseData,
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_LEADS_FAILURE, error });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  }
};

export const GetCustomers =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.DELETE_ADMIN_ });

    try {
      const res = await Axios.get(
        `/admin/customers?Page=${page}&PerPage=${limit}&SearchBy=${query}`
      );

      dispatch({
        type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
        payload: res.responseData,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_CUSTOMERS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const AssignLead = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.POST_ASSIGN_LEAD_REQUEST });

  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    const res = await Axios.post(`/Admin/assign/lead`, data);
    dispatch({
      type: actionTypes.POST_ASSIGN_LEAD_SUCCESS,
      payload: res.responseData,
    });

    Swal.fire("", "Broker assigned", "success").then(function () {
      window.location.href = "/admin/leads";
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    Swal.fire("", error.response.data.message, "info");
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });

    dispatch({
      type: actionTypes.POST_ASSIGN_LEAD_FAILURE,
      error,
    });
  }
};

export const VerifyBroker = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.POST_VERIFY_BROKER_REQUEST });

  try {
    const res = await Axios.put(`/Admin/verify/broker/${id}`);
    console.log(res.status);
    dispatch({
      type: actionTypes.POST_VERIFY_BROKER_SUCCESS,
      payload: res.data,
    });
    Swal.fire("", "Broker verified", "success").then(function () {
      window.location.reload();
    });
  } catch (error) {
    Swal.fire("", error.response.data.message, "info");

    dispatch({ type: actionTypes.POST_VERIFY_BROKER_FAILURE, error });
  }
};

export const GetBrokersCustomers =
  (brokerId, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_BROKERS_CUSTOMERS_REQUEST });

    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true });

      const res = await Axios.get(
        `/admin/broker/customers?Page=${page}&PerPage=${limit}&brokerId=${brokerId}`
      );

      dispatch({
        type: actionTypes.FETCH_BROKERS_CUSTOMERS_SUCCESS,
        payload: res.responseData,
      });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_BROKERS_CUSTOMERS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
      Swal.fire("", error.response.data.message, "info");
    }
  };

export const disconnnetAgent = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.DISCONNECT_AGENT_REQUEST });

  try {
    const res = await Axios.put(`/Admin/unassign/${id}/customer`);

    dispatch({
      type: actionTypes.DISCONNECT_AGENT_SUCCESS,
    });

    Swal.fire("Agent disconnected!").then(function () {
      window.location.reload();
    });
  } catch (error) {
    dispatch({ type: actionTypes.DISCONNECT_AGENT_FAILURE, error });

    Swal.fire("", error.response.data.message, "info");
  }
};

export const CreateUserProductLimit =
  (id, productId, notionalLimit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_ADMINS_REQUEST });

    try {
      const res = await Axios.post(
        `/users/${id}/product-limits/${productId}`,
        notionalLimit
      );
      dispatch({
        type: actionTypes.FETCH_ADMINS_SUCCESS,
        payload: res.data,
      });
      Swal.fire("Product limit added").then(function () {
        window.location.reload();
      });
    } catch (error) {
      console.log(error.response.data.errorMessage);
      dispatch({ type: actionTypes.FETCH_ADMINS_FAILURE, error });
      Swal.fire("Error, " + error.response.data.errorMessage);
    }
  };
