export function getPasswordValidationRules() {
  let map = {
    specialOrNumber: new RegExp(/[^a-zA-Z0-9]|[0-9]/),
    length: new RegExp(/.{8,}/),
  };

  return map;
}

export const PasswordRule = {
  SpecialOrNumber: Symbol("specialOrNumber"),
  Length: Symbol("length"),
};

export const PasswordStrengthLevel = {
  Weak: Symbol("Weak"),
  Good: Symbol("Good"),
  Strong: Symbol("Strong"),
};

export const checkPassword = (str) => {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
};
