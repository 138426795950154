import React, { useEffect, useState } from "react";

// components

import UsersTable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import UserDetail from "../../components/BrokerDetail";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { GetAdmins } from ".././../store/actionCreators/admins";
import { Link } from "react-router-dom";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const role = sessionStorage.getItem("role") === "superadmin" ? true : false;

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAdmins(searchValue, page, limit));
  }, [page, limit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetAdmins(searchValue, page, limit));
    }
  };

  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <UsersTable
            setSearchValue={setSearchValue}
            handleKeyDown={handleKeyDown}
            title="Admins"
            data={state.admins && state.admins.allAdmins.items}
          />
          <Pagination
            page={page}
            onPage={state.admins.allAdmins.currentPage}
            setPage={setPage}
            totalPages={state.admins.allAdmins.totalPages}
          />
        </div>
      </div>
    </>
  );
}
