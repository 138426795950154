import * as actionTypes from "../action-types";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";

export const GetDocument = (type) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_DOCUMENT_REQUEST });

  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    const res = await Axios.get(`/setting/${type}`);
    dispatch({
      type: actionTypes.FETCH_DOCUMENT_SUCCESS,
      payload: res.responseData,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_DOCUMENT_FAILURE, error });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });

    console.log(error);
  }
};

export const UpdateDocument = (type, data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.POST_DOCUMENT_REQUEST });

  try {
    const res = await Axios.put(`/setting/${type}`, data);
    dispatch({
      type: actionTypes.POST_DOCUMENT_SUCCESS,
    });
    Swal.fire("", "Document updated!", "success");
  } catch (error) {
    dispatch({ type: actionTypes.POST_DOCUMENT_FAILURE, error });
    console.log(error);
    Swal.fire("", "Error!", "error");
  }
};

export const ChangePassword = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.post("/account/changepassword", data);
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });

        Swal.fire("", "Password changed!", "success").then(function () {
          window.location.reload();
        });
        resolve();
      } catch (error) {
        Swal.fire("Error", error.response.data.message, "info");
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const GetSubscriptionPrice = (type) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_SUBSCRIPTION_CHARGE_REQUEST });

  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    const res = await Axios.get(`/Payment/charge`);
    dispatch({
      type: actionTypes.FETCH_SUBSCRIPTION_CHARGE_SUCCESS,
      payload: res.responseData,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_SUBSCRIPTION_CHARGE_FAILURE, error });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });

    console.log(error);
  }
};

export const PostSubscriptionPrice = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.POST_SUBSCRIPTION_CHARGE_REQUEST });
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });

  try {
    const res = await Axios.put(`/Payment/charge/settings`, data);
    dispatch({
      type: actionTypes.POST_SUBSCRIPTION_CHARGE_SUCCESS,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });

    Swal.fire("", "Price updated!", "success").then(function () {
      window.location.reload();
    });
  } catch (error) {
    dispatch({ type: actionTypes.POST_SUBSCRIPTION_CHARGE_FAILURE, error });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });

    console.log(error);
    Swal.fire("", "Error!", "error");
  }
};
