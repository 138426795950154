import React, { useEffect, useState } from "react";

// components

import TicketsTable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { GetFeedback } from ".././../store/actionCreators/feedback";
import { Link } from "react-router-dom";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");

  const thead = [
    "Title",
    "Submitted by",
    "Email",
    "Message",
    "Creation Date",
    "Detail",
  ];

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetFeedback(page, limit));
  }, [page, limit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetFeedback(page, limit));
    }
  };
  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <TicketsTable
            setSearchValue={setSearchValue}
            handleKeyDown={handleKeyDown}
            title="Feedback"
            thead={thead}
            data={state.feedback && state.feedback.allFeedback.items}
          />
          <Pagination
            page={page}
            onPage={state.feedback.allFeedback.currentPage}
            setPage={setPage}
            totalPages={state.feedback.allFeedback.totalPages}
          />
        </div>
      </div>
    </>
  );
}
